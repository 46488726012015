// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bp_kC";
export var caseStudyBackground__lineColor = "bp_kz";
export var caseStudyHead__imageWrapper = "bp_kv";
export var caseStudyProjectsSection = "bp_kD";
export var caseStudyQuote__bgLight = "bp_kK";
export var caseStudyQuote__bgRing = "bp_kx";
export var caseStudyQuote__bgRingDark = "bp_kQ";
export var caseStudyQuote__bottomVideo = "bp_kR";
export var caseStudySolution__ring = "bp_kH";
export var caseStudySolution__ringTwo = "bp_kJ";
export var caseStudyVideo = "bp_kM";
export var caseStudyVideo__ring = "bp_kN";
export var caseStudy__bgDark = "bp_ks";
export var caseStudy__bgLight = "bp_kr";